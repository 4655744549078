import { useState } from 'react';
import dynamic from 'next/dynamic';
import cx from 'classnames';

const Modal = dynamic(() => import('@/components/Modal'));
const FormLoader = dynamic(() => import('@/components/Modules/Forms/FormLoader'));

export default function ContactFormModal({ title, staffCard }) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <a onClick={() => setShowModal(true)} className={cx('ml-4 cursor-pointer', staffCard && 'btn-gray px-6 font-medium')}>
                Email
            </a>
            {showModal && (
                <Modal open={showModal} onClose={() => setShowModal(false)}>
                    <div className="staff-modal-container">
                        <h3 className="mb-6 text-center">Contact {title}</h3>
                        <FormLoader modal id={47} staffNameFields={[{ dynamicKey: 'staffName', dynamicValue: title }]} />
                    </div>
                </Modal>
            )}
        </>
    );
}
